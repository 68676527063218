import React from 'react';

import { BUTTON_VARIANTS } from '@belong/themes';
import { buttonClick } from '@belong/analytics';
import { useAnalyticsEvents } from '@belong/providers';
import { isExternalUrl } from '@belong/utils';

import { ActionTileButtonVariant, IActionTile } from '../ActionTiles.types';
import * as styles from './ActionTile.styles';
import { Button } from '../../../actions/Button';
import { ACTION_TILES } from '../../../../helpers/testIds';
import { RichText, TEXT_NODES } from '../../../styles/Typography';

interface IActionTileProps extends IActionTile {
  className?: string;
  hasThreeTiles?: boolean;
}

export const ActionTile: React.FC<IActionTileProps> = ({
  content,
  link,
  image,
  className,
  buttonVariant = BUTTON_VARIANTS.PRIMARY as ActionTileButtonVariant,
  hasThreeTiles
}: IActionTileProps) => {
  const analyticsEvents = useAnalyticsEvents();
  const onClickHandler = () => {
    analyticsEvents.send({
      ...buttonClick({
        label: (link?.label).toLowerCase().replace(/\s/g, '-'),
        text: link.label,
        destination: isExternalUrl(link.href) ? 'external' : 'internal'
      }),
      component: 'action-tile'
    });
  };

  return (
    <styles.Root data-testid={ACTION_TILES.ROOT}>
      <styles.OuterWrapper data-testid={ACTION_TILES.OUTER_WRAPPER} className={className} hasThreeTiles={hasThreeTiles}>
        <styles.ImageWrapper hasThreeTiles={hasThreeTiles}>{image}</styles.ImageWrapper>
        <styles.ContentWrapper>
          <styles.Content>
            <RichText
              html={content}
              h1={TEXT_NODES.headingMedium}
              h2={TEXT_NODES.headingMedium}
              h3={TEXT_NODES.headingMedium}
              h4={TEXT_NODES.headingMedium}
              h5={TEXT_NODES.headingMedium}
              h6={TEXT_NODES.headingMedium}
              p={TEXT_NODES.copyLarge}
            />
          </styles.Content>
          <styles.ButtonMD
            variant={buttonVariant}
            {...(link as any)}
            onClick={onClickHandler}
            data-testid={ACTION_TILES.BUTTON}
          >
            {link?.label}
          </styles.ButtonMD>
        </styles.ContentWrapper>
      </styles.OuterWrapper>
      <styles.ButtonXS
        width="full"
        variant={buttonVariant}
        {...(link as any)}
        onClick={onClickHandler}
        data-testid={ACTION_TILES.BUTTON}
      >
        {link.label}
      </styles.ButtonXS>
    </styles.Root>
  );
};

ActionTile.displayName = 'ActionTile';

export const ActionTileCompact: React.FC<IActionTileProps> = ({
  content,
  link,
  image,
  className,
  buttonVariant = BUTTON_VARIANTS.PRIMARY as ActionTileButtonVariant
}: IActionTileProps) => {
  const analyticsEvents = useAnalyticsEvents();
  const onClickHandler = () => {
    analyticsEvents.send({
      ...buttonClick({
        label: (link?.label).toLowerCase().replace(/\s/g, '-'),
        text: link.label,
        destination: isExternalUrl(link.href) ? 'external' : 'internal'
      }),
      component: 'action-tile'
    });
  };
  return (
    <styles.OuterWrapperCompact data-testid={ACTION_TILES.ROOT} className={className}>
      <styles.ContentWrapper>
        <styles.Content>
          <styles.ImageWrapperCompact>{image}</styles.ImageWrapperCompact>
          <RichText
            html={content}
            h1={TEXT_NODES.headingMedium}
            h2={TEXT_NODES.headingMedium}
            h3={TEXT_NODES.headingMedium}
            h4={TEXT_NODES.headingMedium}
            h5={TEXT_NODES.headingMedium}
            h6={TEXT_NODES.headingMedium}
            p={TEXT_NODES.copyMedium}
          />
        </styles.Content>
        <Button variant={buttonVariant} {...(link as any)} onClick={onClickHandler} data-testid={ACTION_TILES.BUTTON}>
          {link?.label}
        </Button>
      </styles.ContentWrapper>
    </styles.OuterWrapperCompact>
  );
};

ActionTileCompact.displayName = 'ActionTileCompact';

export const ActionTileFullWidth: React.FC<IActionTileProps> = ({
  content,
  link,
  image,
  className,
  buttonVariant = BUTTON_VARIANTS.PRIMARY as ActionTileButtonVariant,
  hasThreeTiles
}: IActionTileProps) => {
  const analyticsEvents = useAnalyticsEvents();
  const onClickHandler = () => {
    analyticsEvents.send({
      ...buttonClick({
        label: (link?.label).toLowerCase().replace(/\s/g, '-'),
        text: link.label,
        destination: isExternalUrl(link.href) ? 'external' : 'internal'
      }),
      component: 'action-tile'
    });
  };

  return (
    <styles.Root data-testid={ACTION_TILES.ROOT}>
      <styles.OuterWrapperFullWidth
        data-testid={ACTION_TILES.OUTER_WRAPPER}
        className={className}
        hasThreeTiles={hasThreeTiles}
      >
        <styles.ImageWrapperFullWidth>{image}</styles.ImageWrapperFullWidth>
        <styles.ContentWrapper>
          <styles.Content>
            <RichText
              html={content}
              h1={TEXT_NODES.headingMedium}
              h2={TEXT_NODES.headingMedium}
              h3={TEXT_NODES.headingMedium}
              h4={TEXT_NODES.headingMedium}
              h5={TEXT_NODES.headingMedium}
              h6={TEXT_NODES.headingMedium}
              p={TEXT_NODES.copyLarge}
            />
          </styles.Content>

          <styles.ButtonMD
            variant={buttonVariant}
            {...(link as any)}
            onClick={onClickHandler}
            data-testid={ACTION_TILES.BUTTON}
          >
            {link?.label}
          </styles.ButtonMD>
        </styles.ContentWrapper>
      </styles.OuterWrapperFullWidth>
      <styles.ButtonXS
        width="full"
        variant={buttonVariant}
        {...(link as any)}
        onClick={onClickHandler}
        data-testid={ACTION_TILES.BUTTON}
      >
        {link.label}
      </styles.ButtonXS>
    </styles.Root>
  );
};

ActionTileFullWidth.displayName = 'ActionTileFullWidth';
