import { media } from '@belong/themes';
import styled from 'styled-components';
import Container from '../../layout/Container';

export const WrapperXS = styled(Container)`
  > div:not(:first-child),
  > a:not(:first-child) {
    margin-top: 1.6rem;
    padding-top: 1.6rem;
  }

  ${media('md')` display: none; `};
`;

export const WrapperMD = styled(Container)`
  display: none;
  margin-top: 0;

  ${media('md')` display: block; `};
`;
