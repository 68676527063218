import styled, { css } from 'styled-components';

import { COLOURS, media, mediaMap } from '@belong/themes';

import { Button } from '../../../actions/Button';
import { Copy } from '../../../styles/Typography/Copy';

const WRAPPER_PADDING = {
  xs: '0',
  lg: '0 4.0rem 0 0'
};

export const ButtonXS = styled(Button)`
  margin-top: 0.8rem;
  ${media('md')` display: none; `}
`;

export const ButtonMD = styled(Button)`
  display: none;
  ${media('md')` display: block; `}
`;

export const OuterWrapper = styled.div<{ hasThreeTiles?: boolean }>`
  display: flex;
  background: ${COLOURS.WHITE};
  width: 100%;
  height: 100%;

  ${mediaMap(
    WRAPPER_PADDING,
    value => css`
      padding: ${value};
    `
  )}

  ${media('xl')`
    ${({ hasThreeTiles }): any => hasThreeTiles && `padding: 0;`}
  `}
`;

export const OuterWrapperFullWidth = styled.div<{ hasThreeTiles?: boolean }>`
  display: flex;
  background: ${COLOURS.WHITE};
  width: 100%;
  height: 100%;
  flex-direction: column;

  ${mediaMap(
    WRAPPER_PADDING,
    value => css`
      padding: ${value};
    `
  )}

  ${media('xl')`
    ${({ hasThreeTiles }): any => hasThreeTiles && `padding: 0;`}
  `}
`;

export const Root = styled.div`
  height: 100%;
  width: 100%;
`;

export const OuterWrapperCompact = styled.div`
  display: flex;
  background: ${COLOURS.WHITE};
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${media('lg')`
      padding-top: 0.8rem;
  `}
`;

export const Content = styled.div`
  padding-top: 0.2rem;

  ${media('md')`
    padding-top: 0;
    margin-bottom: 2.4rem;

    p {
      margin-top: 0.8rem;
    }
  `}
`;

export const StyledCopy = styled(Copy)`
  ${media('md')`margin-top: 0.8rem;`}
`;

export const ImageWrapper = styled.div<{ hasThreeTiles?: boolean }>`
  img {
    flex-shrink: 0;
    align-self: flex-start;
    object-fit: contain;

    width: 7.2rem;
    height: 7.2rem;

    ${media('md')`
      margin-right: 0.8rem;
    `}

    ${media('lg')`
      width: 9.6rem;
      height: 9.6rem;
      margin-right: 1.6rem;
    `}

    ${media('xl')`
      width: 12rem;
      height: 12rem;
      margin-right: 1.6rem;

      ${({ hasThreeTiles }): any =>
        hasThreeTiles &&
        `
        width: 7.2rem;
        height: 7.2rem;
        margin-right: 0.8rem;
      `}
    `}
  }
`;

export const ImageWrapperFullWidth = styled.div`
  img {
    flex-shrink: 0;
    align-self: flex-start;
    object-fit: contain;

    width: 100%;
    height: auto;
    margin-right: 0;
  }
`;

export const ImageWrapperCompact = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  align-self: flex-start;

  img {
    width: 7.2rem;
    height: 7.2rem;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;
