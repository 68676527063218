import React from 'react';

import useRuntime from '@belong/providers/runtime';
import type { ColumnWidth } from '@belong/types';

import { Column, Columns } from '../../ui/index';
import { ActionTile, ActionTileFullWidth, ActionTileCompact } from './ActionTile/ActionTile';
import { IActionTile } from './ActionTiles.types';
import * as styles from './ActionTiles.styles';

interface IActionTilesProps {
  actionTiles: IActionTile[];
  hasFullWidthImageTiles?: boolean;
}

const renderTile = (tile: IActionTile, tilesLength: number, hasFullWidthImageTiles = false) => {
  return hasFullWidthImageTiles ? (
    <ActionTileFullWidth {...tile} hasThreeTiles={tilesLength >= 3} key={tile.id} />
  ) : (
    <ActionTile {...tile} hasThreeTiles={tilesLength >= 3} key={tile.id} />
  );
};

const ActionTiles: React.FC<IActionTilesProps> = ({ actionTiles, hasFullWidthImageTiles }: IActionTilesProps) => {
  const { currentBreakpoint } = useRuntime();
  const showCompactTiles = actionTiles.length >= 3 && currentBreakpoint !== 'xl';

  return (
    <>
      <styles.WrapperXS isPadded={false}>
        {actionTiles.map((tile: IActionTile) => renderTile(tile, actionTiles.length, hasFullWidthImageTiles))}
      </styles.WrapperXS>

      <styles.WrapperMD isPadded>
        <Columns as="ul" role="list" space="xlarge" alignY="stretch">
          {actionTiles.map((tile: IActionTile) => (
            <Column key={`${tile.id}_md`} width={`1/${actionTiles.length}` as ColumnWidth} as="li">
              {showCompactTiles && !hasFullWidthImageTiles ? (
                // don't render compact tile if hasFullWidthImageTiles is true
                <ActionTileCompact {...tile} />
              ) : (
                renderTile(tile, actionTiles.length, hasFullWidthImageTiles)
              )}
            </Column>
          ))}
        </Columns>
      </styles.WrapperMD>
    </>
  );
};

ActionTiles.displayName = 'ActionTiles';

export default ActionTiles;
